import React from 'react';
import CurrencyFormat from 'react-currency-format';
import Table from 'react-bootstrap/Table';

class Order extends React.Component {
  render() {
    if (!this.props.model) return null;
    return (
      <>
        <Table bordered>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Order</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Table borderless size="sm">
                  <tbody>
                    <tr>
                      <th>Customer Number</th>
                      <td>{this.props.model.sellToCustomerId}</td>
                    </tr>   
                    <tr>
                      <th>Ship-to Code</th>
                      <td>{this.props.model.shipToCode}</td>
                    </tr> 
                    <tr>
                      <th>Company Name</th>
                      <td>{this.props.model.billingCompany}</td>
                    </tr> 
                    <tr>
                      <th>Contact</th>
                      <td>{this.props.model.billingFirstName} {this.props.model.billingLastName}</td>
                    </tr> 
                    <tr>
                      <th>Email</th>
                      <td>{this.props.model.billingEmail}</td>
                    </tr>   
                    <tr>
                      <th>Telephone</th>
                      <td>{this.props.model.billingPhone}</td>
                    </tr>               
                  </tbody>
                </Table>
              </td>
              <td>
                <Table borderless size="sm">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{this.props.model.id}</td>
                    </tr> 
                    <tr>
                      <th>PO No / Reference</th>
                      <td>{this.props.model.purchaseOrder}</td>
                    </tr> 
                    <tr>
                      <th>Channel ID</th>
                      <td>{this.props.model.channelId}</td>
                    </tr>
                    <tr>
                      <th>Order Source</th>
                      <td>{this.props.model.orderSource}</td>
                    </tr> 
                    <tr>
                      <th>Send Prices?</th>
                      <td>{this.props.model.sendPrices ? 'Yes' : 'No'}</td>
                    </tr>   
                    <tr>
                      <th>Force Delivery Charge?</th>
                      <td>{this.props.model.fixedDeliveryCharge ? 'Yes' : 'No'}</td>
                    </tr>                
                  </tbody>
                </Table></td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Delivery</th>
              <th>Gift Message</th>
              <th>Service</th>
              <th>Items</th>
            </tr>
          </thead>
          <tbody>
            {this.props.model ? this.props.model.shipments.map((shipment) => {
              return (
                <tr key={shipment.id}>
                  <td>{shipment.id}<br /><small>{shipment.purchaseOrder}</small></td>
                  <td>
                    <div>{shipment.deliveryFirstName} {shipment.deliveryLastName}</div>
                    <div>{shipment.deliveryCompany}</div>
                    <div>{shipment.deliveryAddress1}</div>
                    <div>{shipment.deliveryAddress2}</div>
                    <div>{shipment.deliveryCity}</div>
                    <div>{shipment.deliveryCounty}</div>
                    <div>{shipment.deliveryPostcode}</div>
                    <div>{shipment.deliveryTelephone}</div>
                    <div>{shipment.deliveryEmail}</div>
                  </td>
                  <td>{shipment.giftMessage}</td>
                  <td>{shipment.deliveryService}</td>
                  <td>
                    <Table size="sm" borderless striped={false}>
                      <tbody>
                        {shipment.lines ? shipment.lines.map((line) => {
                          return (
                            <tr key={line.sku}>
                              <td>{line.sku}</td>
                              <td>{line.name}</td>
                              {this.props.model.sendPrices ? (<td className="right"><CurrencyFormat displayType="text" prefix="£" thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} value={line.priceIncVat} /></td>) : null}
                              <td className="right">{line.quantity}</td>
                              {this.props.model.sendPrices ? (<td className="right"><CurrencyFormat displayType="text" prefix="£" thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} value={line.totalIncVat}/></td>) : null}
                            </tr>
                          );
                        }) : null}
                        {this.props.model.fixedDeliveryCharge || (shipment.deliveryIncVat && shipment.deliveryIncVat !== '0') ? (
                            <tr key="deliveryIncVat">
                              <td>DELCHARGE</td>
                              <td>Delivery</td>
                              <td colSpan="2" className="right">1</td>
                              {this.props.model.sendPrices ? (<td className="right"><CurrencyFormat displayType="text" prefix="£" thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} value={shipment.deliveryIncVat} /></td>) : null}
                            </tr>
                          ) : null}
                        {this.props.model.sendPrices && shipment.totalIncVat && shipment.totalIncVat !== '0' ? (
                            <tr key="totalIncVat">
                              <td></td>
                              <td>Total</td>
                              <td colSpan="3" className="right"><CurrencyFormat displayType="text" prefix="£" thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} value={shipment.totalIncVat}/></td>
                            </tr>
                          ) : null}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )           
            }) : null}
          </tbody>
        </Table>
      </>
    );
  }
}

export default Order;