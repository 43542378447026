import React from 'react';
import './App.css';
import ErrorBox from './ErrorBox';
import Order from './Order';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,      
      errorMessage: null,
      order: null,
      errors: null,
      status: 'upload'
    };
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };
     
  // On file upload (click the upload button) 
  onFileUpload = async () => {  
    this.setState({ status: 'uploading', errors: null });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/octet-stream' },
      body: this.state.selectedFile
    }; 
    try {
      const response = await fetch(`${process.env.REACT_APP_ORDERS_HOST}/OrderSpreadsheetProcess`, requestOptions);
      if (response.ok) {
        const order = await response.json();
        this.setState({ order: order, status: 'confirm' });
      } else {
        this.setState({ order: null, status: 'upload' });
        const errorResponse = await response.json();
        if (errorResponse && errorResponse.validationErrors) this.setState({ errors: errorResponse.validationErrors });
      }
    } catch (e) {      
        this.setState({ order: null, errors: [`An error occurred: ${e.message}`] });
    }    
  }; 

  onCancel = () => {
    this.setState({ errorMessage: null, order: null, errors: null, status: 'upload' });
  }

  onConfirm = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: this.state.order.id,
        channelId: this.state.order.channelId,
        confirmationToken: this.state.order.confirmationToken
      })
    }; 
    try {
      const response = await fetch(`${process.env.REACT_APP_ORDERS_HOST}/OrderSpreadsheetConfirm?confirmationToken=${this.state.order.confirmationToken}`, requestOptions);
      if (response.ok) {
        const order = await response.json();
        this.setState({ order, status: 'confirmed' });
      } else {
        const errorResponse = await response.json();
        if (errorResponse && errorResponse.validationErrors) this.setState({ errors: errorResponse.validationErrors });
      }
    } catch (e) {      
        this.setState({ order: null, errors: [`An error occurred: ${e.message}`] });
    }

  }

  render() {
    return (
      <div className="App">
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand>Enotria&amp;Coe Multi-drop Orders</Navbar.Brand>
          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col>
              <section>
                <Form>
                  <Form.Group>
                    <Form.File id="multidrop" accept=".xlsx" label="Upload a multi-drop spreadsheet..." onChange={this.onFileChange} />
                    <Form.Text className="text-muted">
                      Must be a .xlsx Excel file, but can use any filename you like
                    </Form.Text>
                  </Form.Group>
                  
                  {this.state.status !== 'uploading' ? (
                    <Button variant="primary" onClick={this.onFileUpload}>
                      <span>Upload</span>
                    </Button>
                  ) : null}{' '} 
                  {this.state.status === 'uploading' ? (
                    <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      <span>Uploading</span>
                    </Button>
                  ) : null}{' '} 
                  </Form>
                </section>
            </Col>
          </Row>
          <Row>
            <Col>
              <section>
                <ErrorBox errors={this.state.errors} />
              </section>
            </Col>
          </Row>
          <Row>
            <Col>
              <section>
                <Order model={this.state.order} />
              </section>
            </Col>
          </Row>

        </Container>
        <Navbar fixed="bottom" bg="light" variant="light">
          <Container>
            <Button variant="secondary" onClick={this.onCancel}>
            Cancel
            </Button>{' '}
            {this.state.status==='confirm' ? (
              <Button variant="primary" onClick={this.onConfirm}>
                Confirm &amp; Send To Nav
              </Button>
            ) : null}
            {this.state.status==='confirmed' ? (
              <Button variant="success" onClick={this.onCancel}>
                Order Confirmed
              </Button>
            ) : null}  
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default App;
