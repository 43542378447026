import React from 'react';

class ErrorBox extends React.Component {
  render() {
    return this.props.errors ? this.props.errors.map((err, i) => {
      return (<div key={i} className={'alert alert-danger'}>{err}</div>);
    }) : null;
  }
}

export default ErrorBox;
